/*!
Theme Name: Croft House
Theme URI: http://www.crofthouse.co.uk
Author: Abbas Arezoo
Author URI: http://www.thisisweare.com
Description: Croft House website
*/
@font-face {
  font-family: 'Montserrat Extra Light';
  src: url("fonts/montserrat-extralight-webfont.eot");
  src: url("fonts/montserrat-extralight-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/montserrat-extralight-webfont.woff2") format("woff2"), url("fonts/montserrat-extralight-webfont.woff") format("woff"), url("fonts/montserrat-extralight-webfont.svg#montserratextralight") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat Regular';
  src: url("fonts/montserrat-regular-webfont.eot");
  src: url("fonts/montserrat-regular-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/montserrat-regular-webfont.woff2") format("woff2"), url("fonts/montserrat-regular-webfont.woff") format("woff"), url("fonts/montserrat-regular-webfont.svg#montserratregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat Semi Bold';
  src: url("fonts/montserrat-semibold-webfont.eot");
  src: url("fonts/montserrat-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/montserrat-semibold-webfont.woff2") format("woff2"), url("fonts/montserrat-semibold-webfont.woff") format("woff"), url("fonts/montserrat-semibold-webfont.svg#montserratsemibold") format("svg");
  font-weight: normal;
  font-style: normal; }

.hero__img img, .news-feed__img img, .space__img img, .user__img img, .member__img img, .pg-section__promo-img img, .other-facilities__icon img {
  display: block;
  width: 100%;
  height: auto; }

.hp-hero, .hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.pg-footer__nav-list, .main-nav__list, .social-links__list, .spaces-feed__list, .users-feed__list, .user__details-list, .management-feed__list, .other-facilities__list, .news-feed__cats-list {
  display: block;
  list-style-type: none; }

.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block; }

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden; }

.slick-slider {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: 0; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  top: 0;
  left: 0; }

.slick-track:after, .slick-track:before {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

[dir=rtl] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-slider, .slick-list, .slick-track {
  height: 100%; }

html {
  font-size: 57.5%; }
  @media (min-width: 768px) {
    html {
      font-size: 62.5%; } }

body,
input,
select,
option,
button,
textarea {
  font-family: "Montserrat Regular", Arial, sans-serif;
  color: #464646;
  font-size: 14px;
  font-size: 1.4rem; }
  @media (min-width: 768px) {
    body,
    input,
    select,
    option,
    button,
    textarea {
      font-family: "Montserrat Extra Light", Arial, sans-serif; } }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat Semi Bold", Arial, sans-serif;
  font-weight: normal;
  margin: 0;
  padding: 0; }

* {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

body {
  margin: 0; }

a {
  background-color: transparent;
  cursor: pointer; }

a:active,
a:hover {
  outline: 0; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input select,
option,
button,
textarea {
  -webkit-appearance: none;
  border-radius: 0; }

p, ul, ol, blockquote {
  margin: 0;
  padding: 0; }

svg, i {
  display: block; }

svg {
  width: inherit;
  height: inherit; }

body {
  background-color: #f3f4f4; }

.site-wrapper {
  position: relative;
  min-height: 100vh; }

.inner {
  position: relative;
  max-width: 1290px;
  margin: 0 auto;
  padding: 0 2rem; }

.error-pg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #6ec1b3; }

.error-pg__msg {
  padding: 0 2rem;
  text-align: center;
  color: #fff; }
  @media (orientation: landscape), (min-width: 768px) {
    .error-pg__msg {
      width: 50%;
      margin: 0 auto; } }

.btn, .btn--white, .btn--teal {
  display: block;
  height: 3rem;
  padding: 0 2rem;
  border: 3px solid;
  font-family: "Montserrat Semi Bold", Arial, sans-serif;
  line-height: 2.5rem;
  text-decoration: none;
  text-transform: uppercase; }

.btn--white {
  border-color: rgba(255, 255, 255, 0.5);
  color: #fff; }
  @media (min-width: 1024px) {
    .btn--white:hover {
      border-color: #fff; } }

.btn--teal {
  border-color: rgba(110, 193, 179, 0.5);
  color: #6ec1b3; }
  @media (min-width: 1024px) {
    .btn--teal:hover {
      border-color: #6ec1b3; } }

.blue-scheme .btn, .blue-scheme .btn--white, .blue-scheme .btn--teal {
  border-color: rgba(91, 116, 147, 0.5);
  color: #5b7493; }
  @media (min-width: 1024px) {
    .blue-scheme .btn:hover, .blue-scheme .btn--white:hover, .blue-scheme .btn--teal:hover {
      border-color: #5b7493; } }

.red-scheme .btn, .red-scheme .btn--white, .red-scheme .btn--teal {
  border-color: rgba(234, 97, 93, 0.5);
  color: #ea615d; }
  @media (min-width: 1024px) {
    .red-scheme .btn:hover, .red-scheme .btn--white:hover, .red-scheme .btn--teal:hover {
      border-color: #ea615d; } }

.yellow-scheme .btn, .yellow-scheme .btn--white, .yellow-scheme .btn--teal {
  border-color: rgba(58, 53, 17, 0.5);
  color: #3a3511; }
  @media (min-width: 1024px) {
    .yellow-scheme .btn:hover, .yellow-scheme .btn--white:hover, .yellow-scheme .btn--teal:hover {
      border-color: #3a3511; } }

.pg-footer--outer {
  padding: 4rem 0;
  background-color: #6ec1b3;
  font-size: 14px;
  font-size: 1.4rem; }
  @media (min-width: 1280px) {
    .pg-footer--outer {
      padding: 8rem 0; } }

@media (orientation: landscape), (min-width: 768px) {
  .pg-footer {
    display: flex;
    flex-wrap: wrap; } }

.pg-footer__nav {
  margin: 0 0 2rem; }
  @media (orientation: landscape), (min-width: 768px) {
    .pg-footer__nav {
      width: 50%; } }
  @media (min-width: 1024px) and (orientation: landscape) {
    .pg-footer__nav {
      width: 33.333%; } }
  @media (min-width: 1024px) {
    .pg-footer__nav {
      width: 33.333%; } }

.pg-footer__nav-list {
  margin: 0 0 2rem;
  text-align: center; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .pg-footer__nav-list {
      text-align: left; } }
  @media (min-width: 1024px) {
    .pg-footer__nav-list {
      text-align: left; } }
  .pg-footer__nav-list li {
    line-height: 1.5; }
  .pg-footer__nav-list a {
    color: #fff;
    text-decoration: none; }
    @media (min-width: 1024px) {
      .pg-footer__nav-list a:hover {
        text-decoration: underline; } }

.pg-footer .social-links {
  margin: 0 0 2rem; }

.pg-footer .social-links__list {
  justify-content: center; }
  @media (min-width: 1024px) and (orientation: landscape) {
    .pg-footer .social-links__list {
      justify-content: flex-start; } }
  @media (min-width: 1024px) {
    .pg-footer .social-links__list {
      justify-content: flex-start; } }
  .pg-footer .social-links__list li {
    margin: 0 1rem 0 0; }

.pg-footer__address {
  margin: 0 0 2rem;
  text-align: center; }
  @media (orientation: landscape), (min-width: 768px) {
    .pg-footer__address {
      width: 50%; } }
  @media (min-width: 1024px) and (orientation: landscape) {
    .pg-footer__address {
      width: 33.333%;
      text-align: left; } }
  @media (min-width: 1024px) {
    .pg-footer__address {
      width: 33.333%;
      text-align: left; } }
  .pg-footer__address p, .pg-footer__address ul {
    color: #fff;
    line-height: 1.5; }
  .pg-footer__address ul {
    list-style-type: none; }
  .pg-footer__address a {
    color: #fff;
    text-decoration: none; }
    @media (min-width: 1024px) {
      .pg-footer__address a:hover {
        text-decoration: underline; } }

.pg-footer__cta {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (orientation: landscape), (min-width: 768px) {
    .pg-footer__cta {
      width: 100%; } }
  @media (min-width: 1024px) and (orientation: landscape) {
    .pg-footer__cta {
      width: 33.333%; } }
  @media (min-width: 1024px) {
    .pg-footer__cta {
      width: 33.333%; } }

.pg-footer__cta-logo {
  display: block;
  margin: 0 0 2rem; }
  .pg-footer__cta-logo i {
    width: 10rem;
    height: 6rem; }
    @media (min-width: 1024px) {
      .pg-footer__cta-logo i {
        width: 12rem;
        height: 7rem; } }
  .pg-footer__cta-logo svg {
    fill: #fff; }

.pg-footer__cta-download {
  display: inline-block;
  height: 3rem;
  padding: 0 2rem;
  border: 3px solid rgba(255, 255, 255, 0.5);
  font-family: "Montserrat Semi Bold", Arial, sans-serif;
  line-height: 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff; }
  @media (min-width: 1024px) {
    .pg-footer__cta-download:hover {
      border-color: #fff; } }

.blue-scheme .pg-footer--outer {
  background-color: #5b7493; }

.red-scheme .pg-footer--outer {
  background-color: #ea615d; }

.yellow-scheme .pg-footer--outer {
  background-color: #fcdf65; }

.yellow-scheme .pg-footer__nav-list a {
  color: #3a3511; }

.yellow-scheme .pg-footer__address p, .yellow-scheme .pg-footer__address ul {
  color: #3a3511; }

.yellow-scheme .pg-footer__address a {
  color: #3a3511; }

.yellow-scheme .pg-footer__cta-download {
  border: 3px solid rgba(58, 53, 17, 0.5);
  color: #3a3511; }
  @media (min-width: 1024px) {
    .yellow-scheme .pg-footer__cta-download:hover {
      border-color: #3a3511; } }

.yellow-scheme .pg-footer__cta-logo svg {
  fill: #3a3511; }

.std-content h1, .std-content h2, .std-content h3, .std-content h4, .std-content h5, .std-content h6 {
  margin: 0 0 2rem;
  line-height: 1.333; }
  .std-content h1 a, .std-content h2 a, .std-content h3 a, .std-content h4 a, .std-content h5 a, .std-content h6 a {
    color: inherit; }

.std-content h2 {
  text-transform: uppercase; }

.std-content h1, .std-content h2 {
  font-size: 16px;
  font-size: 1.6rem; }
  @media (min-width: 768px) {
    .std-content h1, .std-content h2 {
      font-size: 24px;
      font-size: 2.4rem; } }

.std-content h3, .std-content h4, .std-content h5 {
  font-size: 14px;
  font-size: 1.4rem; }
  @media (min-width: 768px) {
    .std-content h3, .std-content h4, .std-content h5 {
      font-size: 16px;
      font-size: 1.6rem; } }

.std-content * + h2, .std-content * + h3, .std-content * + h4, .std-content * + h5 {
  margin: 4rem 0 2rem; }

.std-content ul, .std-content ol {
  margin: 0 0 2rem 2rem; }

.std-content li {
  margin: 0 0 0.5rem; }

.std-content p {
  margin: 0 0 2rem;
  line-height: 1.5; }

.std-content p a, .std-content ul a, .std-content ol a {
  color: inherit;
  text-decoration: underline; }
  @media (min-width: 1024px) {
    .std-content p a:hover, .std-content ul a:hover, .std-content ol a:hover {
      color: #6ec1b3; } }

.std-content strong {
  font-family: "Montserrat Semi Bold", Arial, sans-serif;
  font-weight: normal; }

img.alignright, p img.alignright {
  float: right;
  margin: 2rem 0 2rem 2rem; }

img.alignleft, p img.alignleft {
  float: left;
  margin: 2rem 4rem 2rem 0; }

img.aligncenter, p img.aligncenter {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto; }

img.alignnone, p img.alignnone {
  display: block;
  max-width: 100%;
  height: auto; }

.wp-caption img {
  display: block;
  width: 100%;
  height: auto;
  margin: 0 auto 1rem; }

.wp-caption.alignright {
  float: right;
  margin: 2rem 0 2rem 2rem; }

.wp-caption.alignleft {
  float: left;
  margin: 2rem 4rem 2rem 0; }

.wp-caption.aligncenter {
  width: 100% !important;
  height: auto !important; }

.wp-caption.alignnone {
  max-width: 100% !important;
  height: auto !important; }

.wp-caption-text {
  font-family: "Montserrat Semi Bold", Arial, sans-serif; }

.video-embed {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  margin: 4rem 0;
  height: 0;
  overflow: hidden; }
  .video-embed iframe,
  .video-embed object,
  .video-embed embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

@media (min-width: 1024px) {
  .blue-scheme .std-content p a:hover, .blue-scheme .std-content ul a:hover, .blue-scheme .std-content ol a:hover {
    color: #5b7493; } }

.blue-scheme .wp-caption-text {
  color: #5b7493; }

@media (min-width: 1024px) {
  .red-scheme .std-content p a:hover, .red-scheme .std-content ul a:hover, .red-scheme .std-content ol a:hover {
    color: #ea615d; } }

.red-scheme .wp-caption-text {
  color: #ea615d; }

@media (min-width: 1024px) {
  .yellow-scheme .std-content p a:hover, .yellow-scheme .std-content ul a:hover, .yellow-scheme .std-content ol a:hover {
    color: #3a3511; } }

.yellow-scheme .wp-caption-text {
  color: #3a3511; }

input[type=text],
input[type=email],
input[type=password],
input[type=tel],
textarea {
  border: 3px solid #f3f4f4; }

input[type=text],
input[type=email],
input[type=password],
input[type=tel] {
  width: 100%;
  height: 5rem;
  padding: 0 1rem; }

textarea {
  width: 100%;
  height: 20rem;
  padding: 1rem; }

input[type=submit] {
  display: block;
  height: 3rem;
  padding: 0 2rem;
  border: 3px solid rgba(110, 193, 179, 0.5);
  background-color: #fff;
  font-family: "Montserrat Semi Bold", Arial, sans-serif;
  line-height: 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  color: #6ec1b3; }
  @media (min-width: 1024px) {
    input[type=submit]:hover {
      border-color: #6ec1b3; } }

.pg-header--outer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  width: 100%;
  padding: 2rem 0; }

.pg-header {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .pg-header {
      flex-direction: row; } }

.pg-header__logo {
  order: 2; }
  @media (orientation: landscape) {
    .pg-header__logo {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%); } }
  @media (min-width: 768px) and (orientation: landscape) {
    .pg-header__logo {
      position: static;
      transform: translateX(0); } }
  @media (min-width: 768px) {
    .pg-header__logo {
      order: 1; } }
  .pg-header__logo i {
    width: 10rem;
    height: 6rem;
    margin: 0 auto; }
    @media (min-width: 1024px) {
      .pg-header__logo i {
        width: 12rem;
        height: 7rem; } }
  .pg-header__logo svg {
    fill: #fff; }

.pg-header__links {
  order: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 0 2rem; }
  @media (min-width: 768px) {
    .pg-header__links {
      order: 2;
      justify-content: flex-end;
      margin: 0; } }

.pg-header__links .social-links {
  display: none; }
  @media (min-width: 768px) {
    .pg-header__links .social-links {
      display: block;
      margin: 0 2rem; } }
  @media (min-width: 1024px) {
    .pg-header__links .social-links {
      margin: 0 4rem; } }

.donate-btn {
  display: block;
  height: 3rem;
  padding: 0 2rem;
  border: 3px solid;
  font-family: "Montserrat Semi Bold", Arial, sans-serif;
  line-height: 2.5rem;
  text-decoration: none;
  text-transform: uppercase;
  border-color: rgba(255, 255, 255, 0.5);
  color: #fff; }
  @media (min-width: 1024px) {
    .donate-btn:hover {
      border-color: #fff; } }

.main-nav__toggle {
  width: 4rem;
  height: 4rem; }
  @media (min-width: 1024px) {
    .main-nav__toggle {
      transition: all 0.25s; }
      .main-nav__toggle:hover {
        transform: scale(1.1); } }
  .main-nav__toggle.hide {
    display: none; }
  .main-nav__toggle i {
    width: 4rem;
    height: 4rem;
    padding: 0.5rem; }
    .main-nav__toggle i svg {
      width: 3rem;
      height: 3rem; }
  .main-nav__toggle svg {
    fill: #fff; }

.main-nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  display: none;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8rem 0;
  background-color: rgba(110, 193, 179, 0.9); }
  @media (min-width: 768px) {
    .main-nav {
      display: flex;
      top: -100%;
      transition: all 0.33s ease-out; } }
  .main-nav.open {
    display: flex; }
    @media (min-width: 768px) {
      .main-nav.open {
        top: 0; } }

.main-nav__close {
  width: 4rem;
  height: 4rem; }
  @media (min-width: 1024px) {
    .main-nav__close {
      transition: all 0.25s; }
      .main-nav__close:hover {
        transform: scale(1.1); } }
  @media (min-width: 768px) {
    .main-nav__close {
      margin: 1rem 0 4rem; } }
  .main-nav__close i {
    width: 4rem;
    height: 4rem;
    padding: 0.5rem; }
    .main-nav__close i svg {
      width: 3rem;
      height: 3rem; }
  .main-nav__close svg {
    fill: #fff; }

.main-nav__list li {
  margin: 2rem 0;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem; }
  @media (min-width: 768px) {
    .main-nav__list li {
      font-size: 30px;
      font-size: 3rem; } }

.main-nav__list a {
  font-family: "Montserrat Semi Bold", Arial, sans-serif;
  text-decoration: none;
  color: #fff; }
  @media (min-width: 1024px) {
    .main-nav__list a:hover {
      text-decoration: underline; } }

.blue-scheme .main-nav {
  background-color: rgba(91, 116, 147, 0.9); }

.red-scheme .main-nav {
  background-color: rgba(234, 97, 93, 0.9); }

@media (min-width: 768px) {
  .yellow-scheme .pg-header__logo svg {
    fill: #3a3511; }
  .yellow-scheme .donate-btn {
    border-color: rgba(58, 53, 17, 0.5);
    color: #3a3511; } }
  @media (min-width: 768px) and (min-width: 1024px) {
    .yellow-scheme .donate-btn:hover {
      border-color: #3a3511; } }

@media (min-width: 768px) {
  .yellow-scheme .main-nav__toggle svg {
    fill: #3a3511; }
  .yellow-scheme .main-nav {
    background-color: rgba(252, 223, 101, 0.9); }
  .yellow-scheme .main-nav__close svg {
    fill: #3a3511; }
  .yellow-scheme .main-nav__list a {
    color: #3a3511; } }

.hp-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  background-color: #6ec1b3; }
  @media screen and (min-width: 1024px) and (min-height: 768px) {
    .hp-hero {
      border-bottom: 2rem solid #f3f4f4; } }
  @media (min-width: 1280px) {
    .hp-hero {
      border-width: 5rem; } }
  .hp-hero:after {
    mix-blend-mode: multiply;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #6ec1b3; }

.no-backgroundblendmode .hp-hero:after {
  background-color: rgba(110, 193, 179, 0.85); }

.hp-hero__headline {
  position: relative;
  z-index: 2;
  width: 100%;
  color: #fff;
  text-align: center; }
  @media (min-width: 768px) {
    .hp-hero__headline {
      text-align: left; } }
  @media (min-width: 1280px) {
    .hp-hero__headline {
      padding: 0 0 10rem; } }
  .hp-hero__headline h1 {
    margin: 0 0 2rem;
    line-height: 1;
    letter-spacing: -0.015em;
    font-size: 30px;
    font-size: 3rem; }
    @media (min-width: 768px) {
      .hp-hero__headline h1 {
        font-size: 70px;
        font-size: 7rem; } }
  .hp-hero__headline h2 {
    font-family: "Montserrat Extra Light", Arial, sans-serif;
    text-transform: uppercase;
    font-size: 16px;
    font-size: 1.6rem; }
    @media (min-width: 768px) {
      .hp-hero__headline h2 {
        font-size: 24px;
        font-size: 2.4rem; } }

.hero {
  position: relative;
  display: flex;
  align-items: center;
  height: 100vh; }
  @media (min-width: 768px) {
    .hero {
      height: 75vh;
      overflow: hidden;
      background: none !important; } }
  .hero:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply; }
  .hero:after {
    display: none; }
    @media (min-width: 768px) {
      .hero:after {
        display: block;
        content: "";
        position: absolute;
        z-index: 3; } }

.hero__img {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%; }
  @media (min-width: 768px) {
    .hero__img {
      display: block; } }
  @media (min-width: 768px) {
    .hero__img img {
      position: absolute; } }

.hero__headline {
  position: relative;
  z-index: 4;
  width: 100%;
  padding: 4rem 0 0; }
  @media (min-width: 768px) {
    .hero__headline {
      padding: 0; } }

.hero__headline--content {
  color: #fff;
  text-align: center;
  width: 75%;
  margin: 0 auto; }
  @media (min-width: 768px) {
    .hero__headline--content {
      margin: 0;
      text-align: left; } }
  @media (min-width: 1024px) {
    .hero__headline--content {
      width: 50%; } }
  .hero__headline--content h1 {
    line-height: 1;
    letter-spacing: -0.015em;
    font-size: 30px;
    font-size: 3rem; }
    @media (min-width: 768px) {
      .hero__headline--content h1 {
        font-size: 70px;
        font-size: 7rem; } }
  .hero__headline--content h2 {
    font-family: "Montserrat Extra Light", Arial, sans-serif;
    font-size: 24px;
    font-size: 2.4rem; }
    @media (min-width: 768px) {
      .hero__headline--content h2 {
        font-size: 70px;
        font-size: 7rem; } }
  .hero__headline--content p {
    line-height: 1.5;
    color: #fff;
    font-size: 16px;
    font-size: 1.6rem; }
    @media (min-width: 1024px) {
      .hero__headline--content p {
        width: 75%; } }
  .hero__headline--content h2 + p {
    margin: 2rem 0 0; }

.no-backgroundblendmode .blue-scheme .hero:before {
  background-color: rgba(91, 116, 147, 0.85); }

.no-backgroundblendmode :before,
.no-backgroundblendmode .red-scheme .hero:before {
  background-color: rgba(234, 97, 93, 0.85); }

.no-backgroundblendmode :before,
.no-backgroundblendmode .yellow-scheme .hero:before {
  background-color: rgba(252, 223, 101, 0.85); }

.blue-scheme .hero:before {
  background-color: #5b7493; }

@media (min-width: 768px) {
  .blue-scheme .hero:after {
    bottom: 5rem;
    right: -15rem;
    width: 30rem;
    height: 30rem;
    background-image: url("images/grid-1.svg");
    opacity: 0.33; } }

@media (min-width: 1024px) {
  .blue-scheme .hero:after {
    right: -5rem; } }

@media (orientation: portrait), (min-width: 1024px) {
  .blue-scheme .hero:after {
    width: 40rem;
    height: 40rem; } }

@media (min-width: 1280px) {
  .blue-scheme .hero:after {
    width: 50rem;
    height: 50rem; } }

@media (min-width: 768px) {
  .blue-scheme .hero__img img {
    bottom: 0;
    right: 0;
    width: 60rem;
    height: auto; } }

@media (min-width: 1024px) {
  .blue-scheme .hero__img img {
    width: 50%; } }

.red-scheme .hero:before {
  background-color: #ea615d; }

@media (min-width: 768px) {
  .red-scheme .hero:after {
    bottom: -5rem;
    right: -5rem;
    width: 30rem;
    height: 30rem;
    background-image: url("images/grid-2.svg");
    opacity: 0.33; } }

@media (min-width: 1280px) {
  .red-scheme .hero:after {
    right: 15rem;
    width: 50rem;
    height: 50rem; } }

@media (min-width: 768px) {
  .red-scheme .hero__img img {
    top: 30%;
    left: 45%;
    width: 40rem;
    height: auto; } }

@media (min-width: 1280px) {
  .red-scheme .hero__img img {
    top: 20%;
    left: 40%;
    width: 60rem; } }

.yellow-scheme .hero:before {
  background-color: #fcdf65; }

@media (min-width: 768px) {
  .yellow-scheme .hero:after {
    bottom: -5rem;
    left: 50%;
    width: 30rem;
    height: 30rem;
    background-image: url("images/grid-3.svg");
    opacity: 0.33; } }

@media (min-width: 1024px) {
  .yellow-scheme .hero:after {
    left: 40%; } }

@media (min-width: 1024px) and (orientation: portrait) {
  .yellow-scheme .hero:after {
    width: 50%;
    height: 50%; } }

@media (min-width: 1280px) {
  .yellow-scheme .hero:after {
    width: 50rem;
    height: 50rem; } }

@media (min-width: 768px) {
  .yellow-scheme .hero__img img {
    top: 30%;
    right: 0;
    width: 60rem;
    height: auto; } }

@media (min-width: 1024px) {
  .yellow-scheme .hero__img img {
    width: 50%; } }

@media (min-width: 1280px) {
  .yellow-scheme .hero__img img {
    top: 20%; } }

@media (min-width: 768px) {
  .yellow-scheme .hero__headline--content {
    color: #3a3511; }
    .yellow-scheme .hero__headline--content p {
      color: #3a3511; } }

.std-banner {
  padding: 17rem 0 2rem;
  background-color: #6ec1b3;
  color: #fff; }
  @media (orientation: landscape), (min-width: 768px) {
    .std-banner {
      padding: 12rem 0 2rem; } }
  @media (min-width: 1024px) {
    .std-banner {
      padding: 15rem 0 2rem; } }
  .std-banner h1 {
    margin: 0 0 2rem;
    line-height: 1;
    letter-spacing: -0.015em;
    font-size: 30px;
    font-size: 3rem; }

.std-banner__content {
  text-align: center; }
  @media (orientation: landscape), (min-width: 768px) {
    .std-banner__content {
      text-align: left; } }

.blue-scheme .std-banner {
  background-color: #5b7493; }

.red-scheme .std-banner {
  background-color: #ea615d; }

.yellow-scheme .std-banner {
  background-color: #fcdf65;
  color: #3a3511; }

.hp-quicklinks {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%; }
  @media screen and (min-width: 1024px) and (max-height: 767px) {
    .hp-quicklinks {
      bottom: auto;
      top: 100%;
      padding: 2rem; } }
  @media (min-width: 1024px) {
    .hp-quicklinks {
      bottom: -2rem; } }
  @media (min-width: 1280px) {
    .hp-quicklinks {
      bottom: -5rem; } }

.hp-quicklinks__list {
  display: flex;
  flex-direction: column;
  max-width: 1290px;
  margin: 0 auto;
  list-style-type: none; }
  @media (orientation: landscape), (min-width: 768px) {
    .hp-quicklinks__list {
      flex-direction: row; } }
  @media screen and (min-width: 1024px) and (min-height: 768px) {
    .hp-quicklinks__list {
      justify-content: space-between; } }
  @media (min-width: 1280px) {
    .hp-quicklinks__list {
      padding: 0 2rem; } }

.hp-quicklink {
  width: 100%; }
  @media screen and (min-width: 1024px) and (min-height: 768px) {
    .hp-quicklink {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 32%; } }
  .hp-quicklink a {
    position: relative;
    display: flex;
    padding: 2rem;
    color: #fff;
    text-decoration: none; }
    @media screen and (min-width: 1024px) and (min-height: 768px) {
      .hp-quicklink a {
        border: 1rem solid;
        transition: all 0.25s; } }
    @media (min-width: 1024px) {
      .hp-quicklink a:hover i {
        transform: scale(1.25); } }
  .hp-quicklink i {
    align-self: flex-end;
    width: 1.5rem;
    height: 1.5rem; }
    .hp-quicklink i svg {
      width: 1.5rem;
      height: 1.5rem; }
    @media (min-width: 1024px) {
      .hp-quicklink i {
        transition: all 0.25s; } }
  .hp-quicklink svg {
    fill: #fff; }
  .hp-quicklink:nth-child(1) a {
    background-color: #5b7493; }
    @media screen and (min-width: 1024px) and (min-height: 768px) {
      .hp-quicklink:nth-child(1) a {
        background-color: rgba(91, 116, 147, 0.85);
        border-color: rgba(91, 116, 147, 0.85); } }
    @media (min-width: 1024px) {
      .hp-quicklink:nth-child(1) a:hover {
        background-color: #5b7493; } }
  .hp-quicklink:nth-child(2) a {
    background-color: #ea615d; }
    @media screen and (min-width: 1024px) and (min-height: 768px) {
      .hp-quicklink:nth-child(2) a {
        background-color: rgba(234, 97, 93, 0.85);
        border-color: rgba(234, 97, 93, 0.85); } }
    @media (min-width: 1024px) {
      .hp-quicklink:nth-child(2) a:hover {
        background-color: #ea615d; } }
  .hp-quicklink:nth-child(3) a {
    background-color: #fcdf65;
    color: #3a3511; }
    @media screen and (min-width: 1024px) and (min-height: 768px) {
      .hp-quicklink:nth-child(3) a {
        background-color: rgba(252, 223, 101, 0.85);
        border-color: rgba(252, 223, 101, 0.85); } }
    @media (min-width: 1024px) {
      .hp-quicklink:nth-child(3) a:hover {
        background-color: #fcdf65; } }
  .hp-quicklink:nth-child(3) svg {
    fill: #3a3511; }

.hp-quicklink__text {
  flex: 1; }
  @media screen and (min-width: 1024px) and (min-height: 768px) {
    .hp-quicklink__text {
      height: 15rem;
      padding: 2rem 1rem 0 0; } }
  @media (min-width: 1280px) {
    .hp-quicklink__text {
      height: 20rem;
      padding: 4rem 2rem 0 0; } }
  .hp-quicklink__text h2 {
    text-transform: uppercase;
    font-size: 16px;
    font-size: 1.6rem; }
    @media screen and (min-width: 1024px) and (min-height: 768px) {
      .hp-quicklink__text h2 {
        margin: 0 0 1rem;
        font-size: 24px;
        font-size: 2.4rem; } }
  .hp-quicklink__text p {
    display: none; }
    @media screen and (min-width: 1024px) and (min-height: 768px) {
      .hp-quicklink__text p {
        display: block;
        line-height: 1.5;
        font-size: 14px;
        font-size: 1.4rem; } }

.hp-intro {
  padding: 4rem 0;
  margin: 0 auto; }
  @media (orientation: landscape) {
    .hp-intro {
      width: 75%;
      text-align: center; } }
  @media (min-width: 768px) and (orientation: landscape) {
    .hp-intro {
      padding: 8rem 0; } }
  @media (min-width: 768px) {
    .hp-intro {
      width: 75%;
      padding: 8rem 0;
      text-align: center; } }
  @media screen and (min-width: 1024px) and (max-height: 767px) {
    .hp-intro {
      padding: 12rem 0 8rem; } }
  @media (min-width: 1280px) {
    .hp-intro {
      width: 50%; } }
  .hp-intro p {
    line-height: 1.5;
    color: #464646;
    font-size: 16px;
    font-size: 1.6rem; }
    @media (min-width: 1024px) {
      .hp-intro p {
        font-size: 24px;
        font-size: 2.4rem; } }

.get-in-touch {
  display: flex;
  flex-direction: column;
  margin: 0 0 4rem;
  background-color: #464646; }
  @media (orientation: landscape), (min-width: 768px) {
    .get-in-touch {
      flex-direction: row; } }
  @media (min-width: 768px) {
    .get-in-touch {
      margin: 0 0 8rem; } }

.get-in-touch__info {
  padding: 2rem;
  color: #fff; }
  @media (orientation: landscape), (min-width: 768px) {
    .get-in-touch__info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding: 0 2rem; } }
  .get-in-touch__info h2, .get-in-touch__info p, .get-in-touch__info ul {
    margin: 0 0 1rem; }
  .get-in-touch__info h2 {
    text-transform: uppercase;
    font-size: 16px;
    font-size: 1.6rem; }
  .get-in-touch__info p {
    line-height: 1.5; }
  .get-in-touch__info ul {
    list-style-type: none; }
    .get-in-touch__info ul li {
      line-height: 1.5; }
    .get-in-touch__info ul a {
      color: #fff;
      text-decoration: none; }
      @media (min-width: 1024px) {
        .get-in-touch__info ul a:hover {
          text-decoration: underline; } }

.get-in-touch__actions {
  margin: 0 0 2rem; }
  .get-in-touch__actions a {
    font-family: "Montserrat Semi Bold", Arial, sans-serif;
    color: #fcdf65; }
    @media (min-width: 1024px) {
      .get-in-touch__actions a:hover {
        color: #fff; } }

.get-in-touch__map {
  display: none; }
  @media (orientation: landscape) {
    .get-in-touch__map {
      display: block;
      flex: 1; } }
  @media (min-width: 768px) and (orientation: portrait) {
    .get-in-touch__map {
      display: block;
      flex: 1; } }
  @media (min-width: 1024px) {
    .get-in-touch__map {
      flex: 2; } }
  .get-in-touch__map iframe {
    display: block;
    width: 100%; }

.social-links__list {
  display: flex; }
  .social-links__list li {
    margin: 0 0.5rem; }
  .social-links__list a {
    display: block;
    width: 3rem;
    height: 3rem; }
    @media (min-width: 1024px) {
      .social-links__list a {
        transition: all 0.25s; }
        .social-links__list a:hover {
          transform: scale(1.1); } }
  .social-links__list i {
    width: 3rem;
    height: 3rem; }
    .social-links__list i svg {
      width: 3rem;
      height: 3rem; }
  .social-links__list svg {
    fill: #fff; }

.yellow-scheme .social-links__list svg {
  fill: #3a3511; }

.hp-news-feed {
  padding: 4rem 0;
  background-color: #fff; }
  @media (min-width: 768px) {
    .hp-news-feed {
      padding: 8rem 0; } }

.news-feed__header {
  margin: 0 0 4rem; }
  @media (min-width: 680px) {
    .news-feed__header {
      display: flex; } }
  .news-feed__header h2 {
    margin: 0 0 1rem;
    text-transform: uppercase;
    color: #6ec1b3;
    font-size: 16px;
    font-size: 1.6rem; }
    @media (min-width: 680px) {
      .news-feed__header h2 {
        flex: 1;
        margin: 0 4rem 0 0; } }
    @media (min-width: 1024px) {
      .news-feed__header h2 {
        font-size: 24px;
        font-size: 2.4rem; } }
  .news-feed__header p {
    line-height: 1.5; }
    @media (min-width: 680px) {
      .news-feed__header p {
        flex: 2; } }

.news-feed__list {
  margin: -1rem -1rem 4rem;
  list-style-type: none; }
  @media (orientation: landscape), (min-width: 400px) {
    .news-feed__list {
      column-count: 2;
      column-gap: 0; } }
  @media (min-width: 768px) {
    .news-feed__list {
      column-count: 2; } }
  @media (min-width: 1280px) {
    .news-feed__list {
      column-count: 3; } }

@media (min-width: 768px) {
  .hp-news-feed .news-feed__list {
    column-count: 3; } }

@media (min-width: 1280px) {
  .hp-news-feed .news-feed__list {
    column-count: 4; } }

.news-feed__list-item {
  break-inside: avoid;
  padding: 1rem; }
  .news-feed__list-item a {
    display: block;
    border: 3px solid #f3f4f4;
    transition: all 0.25s;
    text-decoration: none; }
    @media (min-width: 1024px) {
      .news-feed__list-item a:hover {
        border: 3px solid #6ec1b3; } }

.news-feed__list-item--content {
  padding: 1rem 1rem 2rem; }

.news-feed__img {
  margin: 0 0 2rem; }

.news-feed__heading h3 {
  margin: 0 0 1rem;
  line-height: 1.5;
  color: #464646;
  font-size: 16px;
  font-size: 1.6rem; }

.news-feed__meta p {
  color: #464646;
  font-size: 14px;
  font-size: 1.4rem; }

.news-feed__btn {
  display: flex;
  justify-content: center; }

.spaces-feed__list-item {
  padding: 2rem 0; }
  @media (min-width: 768px) {
    .spaces-feed__list-item {
      padding: 4rem 0; } }
  @media (orientation: landscape), (min-width: 768px) {
    .spaces-feed__list-item:nth-child(odd) .space__info {
      order: 2;
      margin: 1rem 0 0 2rem; }
    .spaces-feed__list-item:nth-child(odd) .space__img {
      order: 1; } }
  @media (min-width: 1024px) {
    .spaces-feed__list-item:nth-child(odd) .space__info {
      margin: 1rem 0 0 4rem; } }
  .spaces-feed__list-item:nth-child(even) {
    background-color: #fff; }

@media (orientation: landscape), (min-width: 768px) {
  .space {
    display: flex; } }

@media (orientation: landscape), (min-width: 768px) {
  .space__info {
    flex: 1;
    margin: 1rem 2rem 0 0; } }

@media (min-width: 1024px) {
  .space__info {
    margin: 1rem 4rem 0 0; } }

@media (orientation: landscape), (min-width: 768px) {
  .space__img {
    flex: 1; } }

@media (min-width: 1024px) {
  .space__img {
    flex: 1.5; } }

.space__info h2, .space__info h3, .space__info h4, .space__info h5 {
  font-size: 16px;
  font-size: 1.6rem; }

.space__info h2 {
  margin: 0 0 1rem;
  text-transform: uppercase; }
  @media (min-width: 1024px) {
    .space__info h2 {
      font-size: 24px;
      font-size: 2.4rem; } }

.space__info h3 {
  margin: 0 0 1rem; }

.space__info h4 {
  margin: 0 0 0.5rem; }

.space__info h5 {
  margin: 0 0 2rem; }
  @media (min-width: 1024px) {
    .space__info h5 {
      font-size: 24px;
      font-size: 2.4rem; } }

.space__info p {
  margin: 0 0 2rem;
  line-height: 1.5;
  font-size: 14px;
  font-size: 1.4rem; }

.space__info .btn, .space__info .btn--white, .space__info .btn--teal {
  display: inline-block;
  margin: 0 0 4rem; }
  @media (orientation: landscape), (min-width: 768px) {
    .space__info .btn, .space__info .btn--white, .space__info .btn--teal {
      margin: 0; } }

.blue-scheme .space__img img {
  background-color: #5b7493; }

.red-scheme .space__img img {
  background-color: #ea615d; }

.yellow-scheme .space__img img {
  background-color: #fcdf65; }

.users-feed {
  padding: 2rem 0; }
  @media (min-width: 768px) {
    .users-feed {
      padding: 4rem 0; } }

.users-feed__list {
  margin: 0 -1rem; }
  @media (min-width: 768px) {
    .users-feed__list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row; } }

.users-feed__list-item {
  padding: 1rem; }
  @media (min-width: 768px) {
    .users-feed__list-item {
      width: 50%; } }

@media (min-width: 768px) {
  .user__info {
    display: flex; } }

.user__img {
  width: 20rem;
  margin: 0 auto 2rem; }
  @media (min-width: 768px) {
    .user__img {
      width: auto;
      flex: 1;
      margin: 0 4rem 0 0; } }

@media (min-width: 768px) {
  .user__details {
    flex: 2; } }

.user__details h2 {
  display: block;
  margin: 0 0 2rem;
  line-height: 1.333;
  font-size: 16px;
  font-size: 1.6rem; }
  @media (min-width: 1024px) {
    .user__details h2 {
      font-size: 24px;
      font-size: 2.4rem; } }

.user__details-list {
  margin: 0 0 2rem; }
  .user__details-list li {
    line-height: 1.5; }
  .user__details-list a {
    color: inherit; }
    @media (min-width: 1024px) {
      .user__details-list a:hover {
        color: #6ec1b3; } }

.blue-scheme .user__img img {
  background-color: #5b7493; }

.red-scheme .user__img img {
  background-color: #ea615d; }

.yellow-scheme .user__img img {
  background-color: #fcdf65; }

.management-feed__list {
  margin: 0 -1rem; }
  @media (min-width: 400px) {
    .management-feed__list {
      display: flex;
      flex-wrap: wrap; } }
  @media (min-width: 768px) {
    .management-feed__list {
      margin: 0 -4rem; } }

.management-feed__list-item {
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 4rem; }
  @media (min-width: 400px) {
    .management-feed__list-item {
      flex-direction: row; } }
  @media (min-width: 768px) {
    .management-feed__list-item {
      width: 50%;
      padding: 4rem; } }

.member__img {
  margin: 0 0 2rem; }
  @media (min-width: 400px) {
    .member__img {
      flex: 1;
      margin: 0 2rem 0 0; } }
  @media (min-width: 768px) {
    .member__img {
      margin: 0 4rem 0 0; } }

@media (min-width: 400px) {
  .member__info {
    flex: 3; } }

@media (min-width: 768px) {
  .member__info {
    flex: 2; } }

.member__info h3 {
  margin: 0 0 0.5rem;
  font-size: 16px;
  font-size: 1.6rem; }
  @media (min-width: 768px) {
    .member__info h3 {
      font-size: 24px;
      font-size: 2.4rem; } }

.member__info h4 {
  margin: 0 0 1rem;
  font-size: 16px;
  font-size: 1.6rem; }

.member__info p {
  line-height: 1.5;
  color: #464646;
  font-size: 16px;
  font-size: 1.6rem; }

.blue-scheme .member__img img {
  background-color: #5b7493; }

.red-scheme .member__img img {
  background-color: #ea615d; }

.yellow-scheme .member__img img {
  background-color: #fcdf65; }

.pg-section {
  padding: 2rem 0; }
  @media (min-width: 768px) {
    .pg-section {
      padding: 4rem 0; } }
  .pg-section:nth-child(even) {
    background-color: #fff; }

.pg-section__header {
  margin: 0 0 2rem; }
  @media (min-width: 768px) {
    .pg-section__header {
      width: 75%; } }
  .pg-section__header h2 {
    text-transform: uppercase;
    font-size: 16px;
    font-size: 1.6rem; }
    @media (min-width: 768px) {
      .pg-section__header h2 {
        font-size: 24px;
        font-size: 2.4rem; } }
  .pg-section__header p {
    line-height: 1.5;
    color: #464646;
    font-size: 16px;
    font-size: 1.6rem; }
  .pg-section__header h2 + p {
    margin: 2rem 0 0; }

@media (min-width: 768px) {
  .pg-section__std-content {
    width: 75%; } }

.pg-section__video-outer {
  width: 75%;
  margin: 0 0 2rem; }
  @media (min-width: 768px) {
    .pg-section__video-outer {
      margin: 0 0 4rem; } }

.pg-section__video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0; }
  .pg-section__video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

@media (min-width: 680px) {
  .pg-section__promo {
    display: flex; } }

@media (min-width: 680px) {
  .pg-section__promo-text {
    flex: 2; } }

.pg-section__promo-img {
  margin: 0 0 2rem; }
  @media (min-width: 680px) {
    .pg-section__promo-img {
      flex: 1;
      margin: 0 4rem 0 0; } }

@media (min-width: 680px) {
  .pg-section__promo.right .pg-section__promo-img {
    order: 2;
    margin: 0 0 0 4rem; } }

.spaces-info {
  padding: 4rem 0; }
  .spaces-info h2 {
    margin: 0 0 2rem;
    text-transform: uppercase;
    font-size: 16px;
    font-size: 1.6rem; }
  @media (min-width: 768px) {
    .spaces-info {
      display: flex; } }

@media (min-width: 768px) {
  .other-facilities {
    flex: 2;
    padding: 2rem 4rem 0 0; } }

.other-facilities__list-item {
  margin: 0 0 4rem; }
  @media (orientation: landscape), (min-width: 400px) {
    .other-facilities__list-item {
      position: relative;
      padding: 0 0 0 8rem; } }

.other-facilities__icon {
  width: 5rem;
  height: 5rem;
  margin: 0 auto 2rem; }
  @media (orientation: landscape), (min-width: 400px) {
    .other-facilities__icon {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0; } }

.other-facilities__text p {
  line-height: 1.5; }

.room-hire-rates {
  padding: 2rem;
  background-color: #464646;
  color: #fff; }
  @media (min-width: 768px) {
    .room-hire-rates {
      flex: 1; } }

.calendar {
  padding: 2rem 0;
  background-color: #464646; }
  @media (min-width: 768px) {
    .calendar {
      padding: 4rem 0; } }

.calendar__header {
  margin: 0 0 2rem; }
  .calendar__header h2 {
    text-transform: uppercase;
    color: #fff;
    font-size: 16px;
    font-size: 1.6rem; }
    @media (min-width: 768px) {
      .calendar__header h2 {
        font-size: 24px;
        font-size: 2.4rem; } }

.mec-calendar {
  margin-bottom: 0 !important;
  border: none !important;
  box-shadow: 0 !important; }

.mec-sl-angle-left,
.mec-sl-angle-right {
  display: none !important; }

.mec-table-side-title {
  letter-spacing: 0px !important; }

.mec-calendar .mec-event-article .mec-event-detail {
  font-family: "Montserrat Extra Light", Arial, sans-serif !important; }

.mec-next-month.mec-load-month.mec-next-month,
.mec-previous-month.mec-load-month.mec-previous-month {
  color: #464646 !important; }

.mec-event-footer {
  display: none !important; }

.mec-color.mec-table-side-day {
  border-color: #464646 !important;
  background-color: #fff !important;
  color: #464646 !important; }

.mec-wrap.colorskin-custom .mec-color {
  color: #464646 !important; }

.news-feed--outer {
  display: flex;
  flex-direction: column; }
  @media (min-width: 768px) {
    .news-feed--outer {
      flex-direction: row; } }

.news-feed__intro {
  margin: 0 0 4rem;
  text-align: center; }
  @media (min-width: 768px) {
    .news-feed__intro {
      width: 60%;
      text-align: left; } }
  .news-feed__intro p {
    font-family: "Montserrat Semi Bold", Arial, sans-serif;
    line-height: 1.5;
    font-size: 16px;
    font-size: 1.6rem; }

.news-feed {
  order: 2; }
  @media (min-width: 768px) {
    .news-feed {
      order: 1;
      flex: 2.5; } }

.news-feed__cats--outer {
  order: 1; }
  @media (min-width: 768px) {
    .news-feed__cats--outer {
      order: 2;
      flex: 1;
      padding: 0 0 0 4rem; } }

.news-feed__cats {
  margin: 0 0 4rem;
  border: 3px solid #6ec1b3; }

.news-feed__cats-header {
  padding: 1rem 2rem;
  cursor: pointer; }
  @media (min-width: 768px) {
    .news-feed__cats-header {
      cursor: auto; } }
  .news-feed__cats-header h3 {
    text-transform: uppercase;
    font-size: 16px;
    font-size: 1.6rem; }

.news-feed__cats-list {
  display: none;
  background-color: #f3f4f4; }
  @media (min-width: 768px) {
    .news-feed__cats-list {
      display: block !important; } }
  .news-feed__cats-list li:nth-child(even) a {
    background-color: #fff; }
    @media (min-width: 1024px) {
      .news-feed__cats-list li:nth-child(even) a:hover {
        background-color: #6ec1b3; } }
  .news-feed__cats-list a {
    display: block;
    padding: 1rem 2rem;
    line-height: 1.5;
    color: #464646;
    text-decoration: none;
    transition: all 0.25s; }
    @media (min-width: 1024px) {
      .news-feed__cats-list a:hover {
        background-color: #6ec1b3;
        color: #fff; } }

.news-feed__footer {
  padding: 4rem 0; }
  .news-feed__footer a {
    display: inline-block; }

.pagination-list {
  display: flex;
  justify-content: center;
  font-family: "Montserrat Semi Bold", Arial, sans-serif;
  list-style-type: none;
  text-transform: uppercase; }
  .pagination-list li {
    margin: 0 1rem; }
  .pagination-list a, .pagination-list span {
    display: block;
    padding: 0.5rem 1rem; }
  .pagination-list a {
    border: 3px solid #f3f4f4;
    transition: all 0.25s;
    text-decoration: none;
    color: #464646; }
    @media (min-width: 1024px) {
      .pagination-list a:hover {
        border-color: #6ec1b3; } }
  .pagination-list span {
    border: 3px solid #6ec1b3;
    background-color: #6ec1b3;
    color: #fff; }

@media (min-width: 768px) {
  .contact-form {
    width: 75%; } }

.contact-form__row {
  margin: 0 0 2rem; }

.contact-form__label {
  font-family: "Montserrat Semi Bold", Arial, sans-serif;
  color: #6ec1b3; }

.screen-reader-response {
  display: none; }

.wpcf7-not-valid-tip {
  display: block;
  padding: 1rem 0 0;
  color: #ea615d; }

.wpcf7-response-output.wpcf7-validation-errors {
  color: #ea615d; }

.wpcf7-response-output.wpcf7-validation-success {
  color: #6ec1b3; }

/*# sourceMappingURL=style.css.map */